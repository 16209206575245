import { template as template_98ff61a308224619936b8887a54aa55c } from "@ember/template-compiler";
const FKLabel = template_98ff61a308224619936b8887a54aa55c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
