import { template as template_be132cd42e8b4d768cefd57d334606b4 } from "@ember/template-compiler";
const WelcomeHeader = template_be132cd42e8b4d768cefd57d334606b4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
