import { template as template_7d3c3c49fcf04ab7ad5ab77a4cd0a07a } from "@ember/template-compiler";
const SidebarSectionMessage = template_7d3c3c49fcf04ab7ad5ab77a4cd0a07a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
